import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Button, Container, Form, Col } from "react-bootstrap"
import BanneronePage from "../components/banneronePage"
import CardPagina from "../components/cardPagina"
import Sezione from "../components/sezione"
import TitoloPagina from "../components/titoloPagina"

const RichiestaPreventivo = () => (
  <Layout isPagina={true}>
    <SEO
      title="Richiesta preventivo per impianti fotovoltaici e servizi di ingegneria"
      description="Vuoi realizzare un impianto fotovoltaico ad Anzio, Nettuno, Aprilia, Latina o altre città del Lazio? Richiedi un preventivo! "
    />
    <BanneronePage title="Richiesta Preventivo" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Preventivo per impianto fotovoltaico Lazio, Roma, Latina, Aprilia, Anzio, Nettuno, Ardea" />
      <CardPagina variante="bordoLeft bordoRight">
        <Row>
          <Col sm={4} className="text-center justify-content-center">
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../preventivo/ies-pannelli.jpg"
              alt="Preventivo fotovoltaico lazio"
            />
          </Col>
          <Col sm={8}>
            {" "}
            <p>
              Nel valutare un'offerta tecnico-economica relativa ad un impianto
              fotovoltaico che dovrà essere efficiente per almeno 30 anni,
              bisogna porre molta attenzione alla qualità dei componenti
              offerti. NonsoloEnergia srl ha da sempre investito negli elevati
              standard qualitativi tedeschi dei moduli QCELLS, SOLARWATT gli unici fornitori al mondo di sistemi fotovoltaici
              integrati, dalla materia prima al prodotto finito, che vengono
              sviluppati e prodotti internamente. Inverter FRONIUS, SOLAREDGE, HUAWEI, ZCS o
              FIMER che offrono elevati fattori di conversione dalla corrente
              continua (solare) a quella alternata ed estrema affidabilità nel
              corso degli anni anche grazie ad avanzatissimi sistemi di
              monitoraggio e gestione da remoto via internet.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={4} className="text-center justify-content-center">
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../preventivo/q_cells.jpg"
              alt="Q-CELLS ANZIO NETTUNO"
            />
          </Col>
          <Col xs={4} className="text-center justify-content-center">
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../preventivo/q-cells-germany.png"
              alt="Q-CELLS ANZIO NETTUNO"
            />
          </Col>
          <Col xs={4} className="text-center justify-content-center">
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../preventivo/germany-qcells.png"
              alt="Q-CELLS ANZIO NETTUNO"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4} className="text-center justify-content-center">
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../preventivo/fronius-e-qcells.jpg"
              alt="Q-CELLS ANZIO NETTUNO"
            />
          </Col>
          <Col xs={4} className="text-center justify-content-center">
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../preventivo/q.antum.png"
              alt="Q-CELLS ANZIO NETTUNO"
            />
          </Col>
          <Col xs={4} className="text-center justify-content-center">
            <img
              className="rounded mt-1 mx-auto img-fluid "
              src="../preventivo/panasonic.jpg"
              alt="Q-CELLS ANZIO NETTUNO"
            />
          </Col>
        </Row>
      </CardPagina>

      <Sezione title="Richiesta Preventivo" />
      <CardPagina>
        <Form
          action="https://f5xplobb67.execute-api.eu-west-1.amazonaws.com/dev/"
          method="POST"
        >
          <input
            type="hidden"
            name="_to"
            value="dc9dbf3822fb3b3d98541d2f847c47d3053cc0bebb5eeb"
          />
          <input
            type="hidden"
            name="_redirect"
            value="https://nse-gatsby.netlify.com/form-ok"
          />

          <Form.Row>
            <Form.Group as={Col} sm="5" controlId="nome">
              <Form.Label>Nome e Cognome*</Form.Label>
              <Form.Control
                required
                type="text"
                name="nome e cognome"
                placeholder="Inserisci Nome e Cognome"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="5" controlId="azienda">
              <Form.Label>Denominazione Azienda/Ente Pubblico</Form.Label>
              <Form.Control
                type="text"
                name="Denominazione Azienda/Ente Pubblico"
                placeholder="Campo riservato alle aziende"
              />
            </Form.Group>
            <Form.Group as={Col} sm="5" controlId="regione sociale">
              <Form.Label>Ragione Sociale</Form.Label>
              <Form.Control
                type="text"
                name="Ragione sociale"
                placeholder="Campo riservato alle aziende"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="5" controlId="indirizzo">
              <Form.Label>Comune*</Form.Label>
              <Form.Control
                required
                type="text"
                name="Comune"
                placeholder="Comune"
              />
            </Form.Group>
            <Form.Group as={Col} sm="4" controlId="Provincia">
              <Form.Label>Provincia*</Form.Label>
              <Form.Control
                required
                type="text"
                name="Provincia"
                placeholder="Provincia"
              />
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="Indirizzo">
            <Form.Label>Indirizzo</Form.Label>
            <Form.Control
              type="text"
              name="Indirizzo"
              placeholder="Indirizzo"
            />
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="email">
              <Form.Label>Indirizzo Email*</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="Inserisci la tua Email"
              />
              <Form.Text className="text-muted">
                Digita un indirizzo email corretto per consentirci di
                contattarti
              </Form.Text>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="email">
              <Form.Label>Telefono*</Form.Label>
              <Form.Control
                required
                type="number"
                name="telefono"
                placeholder="Inserisci il tuo numero di Telefono"
              />
              <Form.Text className="text-muted">
                Ti contatteremo noi. Assicurati che sia corretto.
              </Form.Text>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md="7" controlId="superficieTotale">
              <Form.Label>Superficie Totale Disponibile*</Form.Label>
              <Form.Control
                required
                as="select"
                name="Superficie Totale Disponibile"
              >
                <option>fino a 30mq</option>
                <option>fino a 60mq</option>
                <option>fino a 100mq</option>
                <option>fino a 200mq</option>
                <option>fino a 500mq</option>
                <option>fino a 1000mq</option>
                <option>fino a 5000mq</option>
                <option>oltre 5000mq</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="7" controlId="kw da richiedere">
              <Form.Label>kW da richiedere*</Form.Label>
              <Form.Control required as="select" name="kW da Richiedere">
                <option>3 kw</option>
                <option>4 kW</option>
                <option>5 kW</option>
                <option>6 kW</option>
                <option>8 kW</option>
                <option>9 kW</option>
                <option>10 kW</option>
                <option>11 kW</option>
                <option>12 kW</option>
                <option>15 kW</option>
                <option>20 kW</option>
                <option>30 kW</option>
                <option>40 kW</option>
                <option>50 kW</option>
                <option>tra 50kW e 100 kW</option>
                <option>tra 100 e 500 kW</option>
                <option>oltre 500 kW</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="consumi annuali">
              <Form.Label>Impianto con accumulo</Form.Label>
              <Form.Check
                type="checkbox"
                name="Impianto con accumulo"
                label="Impianto con Accumulo"
              />
              <Form.Check
                type="checkbox"
                name="Impianto senza accumulo"
                label="Impianto senza accumulo"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="5" controlId="Ubicazione Impianto">
              <Form.Label>Ubicazione Impianto*</Form.Label>
              <Form.Control required as="select" name="Ubicazione Impianto">
                <option>Terreno</option>
                <option>Tetto piatto</option>
                <option>Tettoia / Pergola</option>
                <option>Tetto Inclinato / A Falda</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="5" controlId="Esposizione">
              <Form.Label>Esposizione*</Form.Label>
              <Form.Control required as="select" name="Esposizione">
                <option>Sud</option>
                <option>Sud-Ovest</option>
                <option>Sud-Est</option>
                <option>Est</option>
                <option>Ovest</option>
                <option>Nord</option>
                <option>Nord-Ovest</option>
                <option>Nord-Est</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              md="5"
              controlId="Accesso al sito di installazione"
            >
              <Form.Label>Accesso al sito di installazione*</Form.Label>
              <Form.Control
                required
                as="select"
                name="Accesso al sito di installazione"
              >
                <option>Tramite Scala Interna</option>
                <option>Tramite Scala Esterna</option>
                <option>Necessità di impalcatura</option>
                <option>Necessità di cestello elevatore</option>
                <option>Terreno</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Group id="finanziamento">
            <Form.Label>Finanziamento con "Formula a costo zero"</Form.Label>
            <Form.Check
              type="checkbox"
              name="Preventivo con Finanziamento"
              label="Preventivo con Finanziamento"
            />
            <Form.Check
              type="checkbox"
              name="Preventivo senza Finanziamento"
              label="Preventivo senza Finanziamento"
            />
          </Form.Group>
          <Form.Group controlId="richiesta">
            <Form.Label>Altri Dati/Informazioni</Form.Label>
            <Form.Control
              as="textarea"
              name="altre informazioni"
              rows="3"
              placeholder="Scrivi altri informazioni utili"
            />
          </Form.Group>
          <Form.Group id="tipo di offerta">
            <Form.Label>Tipo di Offerta</Form.Label>
            <Form.Check
              type="checkbox"
              name="Chiavi in mano (include tutte le voci seguenti)"
              label="Chiavi in mano (include tutte le voci seguenti)"
            />
            <Form.Check
              type="checkbox"
              name="Progettazione"
              label="Progettazione"
            />
            <Form.Check
              type="checkbox"
              name="Pratiche allaccio, ENEL/GSE"
              label="Pratiche allaccio, ENEL/GSE"
            />
            <Form.Check
              type="checkbox"
              name="Fornitura materiali"
              label="Fornitura materiali"
            />
            <Form.Check
              type="checkbox"
              name="Installazione e cert. conformità D.M. 37/08 (ex L.46/90)"
              label="Installazione e cert. conformità D.M. 37/08 (ex L.46/90)"
            />
            <Form.Check type="checkbox" name="Collaudo" label="Collaudo" />
          </Form.Group>
          <Form.Group id="tipo di offerta">
            <Form.Label>
              <b>
                I dati che Vi riguardano saranno archiviati e trattati nel
                rispetto di quanto stabilito in merito al trattamento dei dati
                degli utenti ai fini dell’art. 13 del D.lgs. n. 196/2003, Codice
                in materia di protezione dei dati personali, e ai fini dell’art.
                13 del Regolamento UE n. 2016/679, ed usati esclusivamente per
                la compilazione del presente preventivo.
              </b>
            </Form.Label>
            <Form.Check
              required
              type="checkbox"
              name="Privacy Accettata"
              label="Accetto"
            />
          </Form.Group>
          <div className="text-center">
            <Button className="btnVerde" type="submit">
              Invia la tua richiesta
            </Button>
          </div>
        </Form>
      </CardPagina>
    </Container>
  </Layout>
)

export default RichiestaPreventivo
